import React from 'react';

function Promo() {
    return (
        <div className="promo">
            a <a href="https://www.robertbrockie.com" target="_blank" rel="noopener">robert brockie</a> production
        </div>
    );
}

export default Promo;
